/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import {
  Typography,
  Statistic,
  Icon,
  Card,
  Col,
  Row,
  Progress,
  Alert,
  Button,
} from "antd";
//import * as action from "./state/index";
import { getUserProfile } from './state/index'
import wheel from "shared/assets/wheel.png";
import exideBattery from "shared/assets/exide-battery.svg";


class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    //const { getUser } = this.props;
    //getUser();
    this.props.getUser();
  }

  render() {
    const { wallet, itemLoaded } = this.props;

    return (
      //this.props.itemLoaded ?
      <>
        <div className="container-fluid page-header">
          <Row>
            <Col>
              <h1>
                <span className="font-weight-300">Be a </span>Winna!
              </h1>
            </Col>
          </Row>
        </div>

        <div className="container-fluid p-3">
          <Row>
            <Col>
              <Link to={'./wallet'}>
                <Card hoverable style={{ marginBottom: 40 }} bordered={false} className="ant-card-grey">
                  <h2 className="text-center m-0">
                    <span className="font-weight-300">You have</span><br/>
                    <span className="display-1 font-weight-700">
                      { wallet?.balance } Spins Available
                     </span>
                  </h2>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="text-center">
                <span className="font-weight-300">How to be </span>a big winna
              </h2>
              
              <ul>
                <li>Sell as many Exide batteries as you can and then sell some more!</li>
                <li>Upload your invoices to this Exide-ing app</li>
                <li>Every Exide battery sold gives you one spin, and one entry to the grand prize draw</li>
                <li>Upload. Spin. And stand a chance to WIN</li>
              </ul>

              <Button
                type="primary"
                block
                href="./invoice"
                className="ant-btn-xl"
                //loading={submitting}
              >Upload Invoices</Button>

              <Link to={'./merch'}>
                <div className="bg-primary rounded py-3 px-2 my-5 d-flex justify-content-center box-shadow">
                  <div className="position-relative align-coin">
                    <img className="img-fluid" src={wheel} alt="" />
                    <h2 className="text-white my-2">
                      <span className="font-weight-300">Ready, set, </span>spin!
                    </h2>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>

          <Row>
              <Col>
                <Card style={{ marginBottom: 40 }} bordered={false} className="ant-card-grey">
                    <h2 className="text-center">
                      <span className="font-weight-300">Exide-ing</span><br/>Education
                    </h2>
                    <p>Learn more about the Exide range to power up your customer’s cars with the right battery.</p>
                    <div className="text-center">
                    <Link to={'./education'}><img className="img-fluid" src={exideBattery} alt="Exide" /></Link>
                    </div>
                     <Button
                        type="primary"
                        block
                        href="./education"
                        className="ant-btn-xl"
                        //loading={submitting}
                      >Learn More</Button>
                  </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="bg-primary p-3 block-rounded text-white mt-3 mb-2 box arrow-top">
                  <h6 className="font-weight-700 text-uppercase text-white">Did you know?</h6>
                  <p className="text-xs font-weight-300 mb-0">Exide-ing Education helps you learn about Exide’s product range.</p>
                </div> 
              </Col>
            </Row>
        </div>
      </>

      //: "loading"
    );
  }
}

// const mapStateToProps = ({
//   shared: { 
//     item: { item }, 
//   },
// }) => ({
//   wallet: item?.wallet,
// });

//const mapDispatchToProps = action;

const mapStateToProps = state => {
  const itemLoaded = state.shared.item.itemLoaded;
  const wallet = state.shared.item.item?.wallet;

  return {
    wallet,
    itemLoaded
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getUser: () => {
          dispatch(getUserProfile())
      }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
