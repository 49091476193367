/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable import/no-mutable-exports */
import React, { Component, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Spin,
  Typography,
  Form,
  Input,
  Button,
  Select,
  Card,
  Row,
  Col,
  Upload,
  Icon,
  message,
  DatePicker
} from 'antd'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'
import * as actions from './state/index'
import DynamicFields from './DynamicFields'
import ReturnButton from "./return"
//import { orderBy } from 'natural-orderby';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { formatDate, parseDate } from 'react-day-picker/moment';

const { TextArea } = Input

const { Title } = Typography
const { Option } = Select

// let isSubmitting = false

// function setSubmittState(){
//   isSubmitting = true
// }

let InvoiceForm = ({
  form,
  createinvoice,
  fetchBatteryCodes,
  codes,
  submitting
}) => {
  const [imageUrl, handleImgUrl] = useState(null)
  
  const [encodedImageUrl, handleEncodedImgUrl] = useState('')
  
  const [loading, handleLoading] = useState(false)
  
  const [isButtonLoading, setIsButtonLoading] = React.useState(false)

  useEffect(() => {
    fetchBatteryCodes()
  }, [fetchBatteryCodes])


  function handleDayChange(value) {
    const dateval = moment(value);
    const dateformatted = dateval.format("YYYY-MM-DD");
    //console.log(dateformatted)
    
    form.setFieldsValue({
      date_of_sale: dateformatted
    });
  }

  const handleSubmit = e => {
    e.preventDefault()

    form.validateFields((err, values) => {
      if (err) {
        console.log('Received values of form: ', values)
        return
      }
      
      createinvoice(values, encodedImageUrl)
    })
  }

  // const getBase64 = (img, callback) => {
  //   const reader = new FileReader()
  //   reader.addEventListener('load', () => callback(reader.result))
  //   reader.readAsDataURL(img)
  // }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const beforeUpload = file => {
    
    const allowed = [
      'text/x-comma-separated-values',                                            
      'text/comma-separated-values',                                              
      'application/x-csv',                                                       
      'text/x-csv',                                                            
      'text/csv',                                                                 
      'application/csv',                                                         
      'text/plain',                                
      'image/jpeg',                                                          
      'image/pjpeg',                                                              
      'application/pdf',                                                          
      'application/octet-stream',                                                  
      'image/png',                                                
      'image/x-png',                                                              
      'application/msexcel',                                                    
      'application/x-msexcel',                                                    
      'application/x-ms-excel',                                                 
      'application/x-excel',                                                       
      'application/x-dos_ms_excel',                                               
      'application/xls',                                                          
      'application/x-xls',                                                        
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',    
      'application/vnd.ms-excel'                
    ]

    const type = file.type

    if (allowed.indexOf(type) == -1) {
      message.error('This file type is not allowed!')
    }
  
    const isLt2M = file.size / 1024 / 1024 < 3
    
    if (!isLt2M) {
      message.error('Image must smaller than 3MB!')
    }

    // getBase64(file, imageUri => {
    //   handleEncodedImgUrl(imageUri)
    // })

      getBase64(file).then(
        //data => console.log(data)
        data => handleEncodedImgUrl(data)
      );

      handleImgUrl(file)

      return false
  }

  const uploadButton = (
    <div style={{ borderRadius: '30px' }}>
      <Icon type={loading ? 'loading' : 'plus'} />
    </div>
  )

  const dump = codes 
  ? Object.entries(codes).map(data => (
        <Option key={data[0]} value={data[1]}>
          { data[1]} 
        </Option>
      ))
  : []

  //const list = ['10.04f', '10.039F', '10.038d', '10.037D']

  return (
    <>
    <div className="container-fluid page-header">
     <ReturnButton />
      <Row>
        <Col>
          <h1>
            <span className="font-weight-300">Upload, earn,</span><br/>spin and win!
          </h1>
        </Col>
      </Row>
    </div>

    <div className="container p-3">
     {/* <Row>
        <Col>
        <p>Energise your earnings and use your Battery Bucks to redeem vouchers from Pick ‘n Pay, Checkers and Takealot. All you need to do is...</p>
        <ol>
          <li>Upload your invoices</li>
          <li>Fill in the form</li>
          <li>Use your vouchers to do a trolley dash</li>
        </ol>
        </Col>
      </Row>*/}

      <Row>
        <Col>
          <Form
            //layout="vertical"
            onSubmit={handleSubmit}
            className="invoice-form ant-form-grey mt-3"
          >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                {form.getFieldDecorator('date_of_sale', {
                  rules: [
                      {
                        required: true,
                        message: 'Please enter date of purchase!'
                      }
                    ]
                  })
                (
                  <DayPickerInput
                    inputProps={{ className: 'ant-calendar-picker-input ant-input'}}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder="Date of Sale"
                    format="YYYY-MM-DD"
                    onDayChange={handleDayChange}
                  />
                  )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {form.getFieldDecorator('receipt_number', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a valid invoice number!'
                    }
                  ]
                })(<Input placeholder="Invoice no." />)}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item>
                  {form.getFieldDecorator('quantity', {
                    rules: [
                        {
                          required: true,
                          message: 'Please input your units sold!'
                        }
                    ]
                  })(<Input placeholder="Units sold"/>)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item>
                {form.getFieldDecorator('items', {
                  rules: [
                      {
                        required: true,
                        message: 'Please input your battery codes!'
                    }
                  ]
                })(<Select
                      placeholder="Battery codes"
                      mode="multiple"
                      showArrow
                      >
                      {dump}
                  </Select>)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item className="mb-2">
                {form.getFieldDecorator('upload', {
                  rules: [
                      {
                        required: true,
                        message: 'Please upload your invoice!'
                    }
                  ]
                })(
                  <Upload beforeUpload={beforeUpload}>
                    <Button className="ant-btn-input">
                     Upload invoice <span className="input-help-text ml-1">(JPEG, PNG, PDF, Excel or CSV allowed)</span><Icon type="upload" />
                    </Button>
                  </Upload>,
                )}
              </Form.Item>
              <p className="text-sm mt-1 mb-4 text-center">Max file size <strong>3MB</strong>.</p>
            </Col>
            </Row>

            <Form.Item className="text-center">
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                shape="round"
                //onClick={setSubmittState}
              >
              Submit
              </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="text-sm mt-3"><strong>Remember</strong>, the invoice you upload must be clear and legible. <strong><em>No photos of computer screens.</em></strong></p>
          </Col>
        </Row>

         <Row>
            <Col>
              <div className="bg-primary p-3 block-rounded text-white mt-3 mb-2 box arrow-top">
                <h6 className="font-weight-700 text-uppercase text-white">Did you know?</h6>
                <p className="text-xs font-weight-300 mb-0">The more you learn, the more you sell, the more you spin and the more you win!</p>
              </div> 
            </Col>
          </Row>

      </div>
    </>
  )
}

InvoiceForm = Form.create({ name: 'invoice' })(InvoiceForm)

const mapStateToProps = ({
  shared: {
    item: { item },
    form: { submitting }
  }
}) => ({
  codes: item,
  submitting
})


const mapDispatchToProps = actions

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceForm)