/* eslint-disable import/no-mutable-exports */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import PropTypes from 'prop-types'
import {
  Card,
  Col,
  Row,
  Form,
  Icon,
  Input,
  Button,
  Checkbox,
  Select,
  Spin
} from 'antd'
import MainFooter from 'shared/components/layouts/MainFooter'
import { registerStoreManager } from 'account/state/accountActions'
import { Link } from 'react-router-dom'
import logo from 'shared/assets/logo-light.svg'
import batterybucks from 'shared/assets/batterybucks-logo.svg'


const { Option } = Select

let StoreUserRegister = ({
  submitting,
  //regData,
  regBranches,
  regReps,
  greeting,
  somethingelse,
  form: { getFieldDecorator, validateFields },
  registerStoreManager,
  push
}) => {

  const handleSubmit = e => {
    e.preventDefault()
   
    validateFields((err, values) => {
      if (!err) {
        
        let newValues = {};
        newValues['store-manager'] = {};
        newValues['store-members'] = {};
        
        for (let v in values) {
          let vsplit = v.split('____');
          
          if (1 in vsplit) {
            let newind = parseInt(vsplit[1]) + 1;            
            if (!newValues['store-members'][newind]) {
              newValues['store-members'][newind] = {};
            }
            
            newValues['store-members'][newind][vsplit[0]] = values[v];
          }
          else {
            newValues['store-manager'][vsplit[0]] = values[v];
          }
        }

        registerStoreManager(newValues)
      }
    })
  }
  
  const [values, setValues] = useState({ val: []});

  function createInputs() {
    return values.val.map((el, i) =>
      <div key={i} className="form_wrapper counterhand-form-group">
        <Form.Item>
          {getFieldDecorator &&
          getFieldDecorator('name____'+ i, {
            rules: [
              { required: true, message: 'Please input your Main Member Name!' }
            ]
          })(
            <Input
            style={{ borderRadius: 30 }}
              placeholder="Main Member Name"
              size="large"
              className="mb-3"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator &&
          getFieldDecorator('surname____'+ i, {
            rules: [
              {
                required: true,
                message: 'Please input your Main Member Surname!'
              }
            ]
          })(
            <Input
            style={{ borderRadius: 30 }}
              type="surname"
              placeholder="Main Member Surname"
              size="large"
              className="mb-3"
            />
          )}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator &&
          getFieldDecorator('cellphone____'+ i, {
            rules: [
              {
                required: true,
                message: 'Please input your Main Member Mobile No.!'
              }
            ]
          })(
            <Input
              style={{ borderRadius: 30 }}
              type="text"
              placeholder="Main Member Mobile No."
              size="large"
              className="mb-3"
            />
          )}
        </Form.Item>

        <Form.Item className="opt-in pl-2 mb-4">
          {getFieldDecorator &&
            getFieldDecorator('opt_in', {
              rules: []
            })(
            <Checkbox>I agree to receive communications from Exide.</Checkbox>
            )}
        </Form.Item>

        <Form.Item className="text-center">
          <Button 
              type='primary' 
              size="large" 
              htmlType="button"
              shape="round"
              className="add-more-user-submit" onClick={removeClick.bind(i)}>
            - Remove
          </Button>
         </Form.Item>

      </div>
    );
  }

  function handleChange(event) {
    let vals = [...values.val];
    vals[this] = event.target.value;
    setValues({ val: vals });
  }
  
  const addClick = () => {
    setValues({ val: [...values.val, '']})
  }
  
  function startForm() {
    setValues({ val: [...values.val, '']})
  }
  
  const removeClick = () => {
    let vals = [...values.val];
    vals.splice(this,1);
    setValues({ val: vals });
  }

 let stores = [];

  let branchesHtml = [];
  let branches = regBranches;
 
  for (let b in branches) {
    branchesHtml.push(<Option key={branches[b].id} value={branches[b].id}>{branches[b].display_name}</Option>)
  }

  let repsHtml = [];
  let reps = regReps;

  for (let r in reps) {
    repsHtml.push(<Option key={reps[r].id} value={reps[r].id}>{reps[r].name} {reps[r].surname}</Option>);   
  }

  let locationsHtml = [];
  const locations = [
      {
          id: 1,
          label: 'Western Cape'
      },
      {
          id: 2,
          label: 'Gauteng'
      },
      {
          id: 3,
          label: 'Eastern Cape'
      },
      {
          id: 4,
          label: 'Northern Cape'
      },
      {
          id: 5,
          label: 'Kwazulu Natal'
      },
      {
          id: 6,
          label: 'North West Province'
      },
      {
          id: 7,
          label: 'Freestate'
      },
      {
          id: 8,
          label: 'Limpopo'
      },
      {
          id: 9,
          label: 'Mpumalanga'
      }
  ]

  for (let l in locations) {
    locationsHtml.push(<Option key={locations[l].id} value={locations[l].label}>{locations[l].label}</Option>);   
  }

  const [selectedOption, setSelectedOption] = useState("none");
  
  const handleTypeSelect = e => {
    setSelectedOption(e.value);
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-content">
        <Row>
          <Col span={24} className="text-center mb-5">
             <a href="./">
              <img src={logo} alt="Exide" />
            </a>
          </Col>
          <Col span={24}>
            <Form onSubmit={handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('name', {
                    rules: [
                      { required: true, message: 'Please input your main member name!' }
                    ]
                })(
                  <Input
                  style={{ borderRadius: 30 }}
                    placeholder="Main member name"
                    size="large"
                    className="mb-3"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator &&
                getFieldDecorator('surname', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your main member surname!'
                    }
                  ]
                })(
                  <Input
                  style={{ borderRadius: 30 }}
                    type="surname"
                    placeholder="Main member surname"
                    size="large"
                    className="mb-3"
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator &&
                getFieldDecorator('cellphone', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your Main Member Mobile No.!'
                    }
                  ]
                })(
                  <Input
                  style={{ borderRadius: 30 }}
                    type="text"
                    placeholder="Main Member Mobile No."
                    size="large"
                    className="mb-3"
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator &&
                getFieldDecorator('email', {
                  rules: [
                    {
                      message: 'Please input your main member email address!'
                    }
                  ]
                })(
                  <Input
                  style={{ borderRadius: 30 }}
                    type="text"
                    placeholder="Main member email address"
                    size="large"
                    className="mb-3"
                  />
                )}
              </Form.Item>

              {/* <Form.Item>
              {getFieldDecorator &&
              getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'Please input your Cellphone!'
                  }
                ]
              })(
                <Input

                  type="password"
                  placeholder="password"
                  size="large"
                  className="mb-3"
                />
              )}
              </Form.Item> */}

              {/*<Form.Item>
                {getFieldDecorator &&
                getFieldDecorator('store_id', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your store!'
                    }
                  ]
                })(
                  <Select
                    placeholder="Select store"
                    size="large"
                    className="mb-3"
                    onChange={handleTypeSelect}
                  >
                    {storesHtml}
                  </Select>
                )}
              </Form.Item>*/}

               <Form.Item>
                {getFieldDecorator &&
                  getFieldDecorator('location', {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your region!'
                      }
                    ]
                  })(
                    <Select
                      placeholder="Select region"
                      size="large"
                      className="mb-3"
                      onChange={handleTypeSelect}
                    >
                      {locationsHtml}
                    </Select>
                  )}
              </Form.Item>
              

              <Form.Item>
                {getFieldDecorator &&
                getFieldDecorator('branch_id', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select your branch!'
                    }
                  ]
                })(
                  <Select
                    placeholder="Select branch"
                    size="large"
                    className="mb-3"
                    onChange={handleTypeSelect}
                  >
                    {branchesHtml}
                  </Select>
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator &&
                getFieldDecorator('fnb_rep_id', {
                  rules: [
                    {
                      required: false,
                      message: 'Please select your FNB Rep!'
                    }
                  ]
                })(
                  <Select
                    placeholder="Select FNB Rep"
                    size="large"
                    className="mb-3"
                    onChange={handleTypeSelect}
                  >
                  {repsHtml}
                  </Select>
                )}
              </Form.Item>

              <Form.Item className="opt-in pl-2 mb-4">
                {getFieldDecorator &&
                  getFieldDecorator('opt_in', {
                    rules: []
                  })(
                  <Checkbox>I agree to receive communications from Exide.</Checkbox>
                  )}
              </Form.Item>

              {createInputs()}

              <Form.Item className="text-center mb-4">
                <Button 
                type='primary' 
                size="large" 
                htmlType="button"
                shape="round"
                className="add-more-user-submit" onClick={addClick}>
                  + Add Another Counterhand
                </Button>
              </Form.Item>

              <Form.Item className="text-center">
                <Button
                  type="secondary"
                  htmlType="submit"
                  shape="round"
                  className="login-submit"
                  size="large"
                >
                Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  )
}

StoreUserRegister.defaultProps = {
  //submitting: false
}

StoreUserRegister.defaultProps = {
  push: () => {}
}

StoreUserRegister.propTypes = {
  //submitting: PropTypes.bool,
  form: PropTypes.object.isRequired,
  push: PropTypes.func
}

StoreUserRegister = Form.create({ name: 'register-store' })(StoreUserRegister)

export default StoreUserRegister
