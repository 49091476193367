import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Row, Col } from 'antd'
import battery2 from 'shared/assets/batteries/exide-enhanced-flooded-battery.svg'
import battery1 from 'shared/assets/batteries/exide-silver-calcium.svg'
import battery3 from 'shared/assets/batteries/exide-valve-regulated-lead-acid.svg'

class TermsAndConditions extends Component {

render() {

return (
    <>
      <div className="container-fluid page-header">
        <Row>
          <Col>
            <h1>
              <span className="font-weight-300">Terms and</span><br/>Conditions
            </h1>
          </Col>
        </Row>
      </div>
      <div className="container-fluid p-3 ant-layout-terms">
        <Row>
            <Col>
              <ol>
                <li>
                  <p>To qualify for the Exide-ing Spinna Winna campaign, you must be a registered employee of Midas or NAPA with the following documentation,</p>
                  <ul>
                    <li><p className="mb-1">A South African ID</p></li>
                    <li><p className="mb-1">Work permit if the employee is from abroad</p></li>
                    <li><p className="mb-1">A South African mobile number</p></li>
                  </ul>
                </li>
                <li>
                  <p>To take part in the Exide-ing Spinna Winna campaign, you must register as an individual or as a store at https://www.exide-ing.co.za/register and fill in all required information.</p>
                </li>
                <li>
                  <p>You will need to give consent to receive communication and share your details with First National Battery.</p>
                </li>
              </ol>

              <h6 className="font-weight-700">How to participate</h6>
              <ol start="4">
                <li>
                  <p>Each Midas counterhand or store manager MUST be registered on the Exide-ing app using,</p>
                  <ul>
                    <li><p className="mb-1">Correct mobile number</p></li>
                    <li><p className="mb-1">Correct details as per employment contract</p></li>
                  </ul>
                </li>
                <li><p>Counterhands and store managers MUST sell Exide batteries, and upload their invoices the the Exide-ing app.</p></li>
                <li>
                  <p>To earn spins, you must sell Exide batteries. (All other brands of batteries do not qualify.)</p>
                   <ol type="a">
                    <li><p className="mb-1">For every Exide battery sold, you will earn 1 spin and 1 entry to the grand prize draw.</p></li>
                    <li>
                      <p className="mb-1">For every spin, counterhands can WIN one of FOUR (4) voucher prizes, or an E-prize</p>
                      <ul type="circle">
                        <li>
                          <p className="mb-1">These are the voucher prizes available, and the spinning wheel will randomly pick a prize per spin,</p>
                          <ul type="square">
                            <li>Airtime (R20),</li>
                            <li>KFC (R50),</li>
                            <li>Pick ‘n Pay (R100),</li>
                            <li>BP Fuel (R100).</li>
                          </ul>
                        </li>
                        <li><p className="mb-1">An entry into the Grand Prize draw is included with every spin</p></li>
                        <li><p className="mb-1">An E-Prize: Winning an E-Prize will give you an entry to the weekly draw as well as the grand prize draw. (Further details on Weekly draw further below.)</p></li>
                      </ul>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>You will need to give consent to receive communication and share your details with First National Battery.</p>
                </li>
              </ol>

              <h6 className="font-weight-700">Where you can find your Exide-ing spins</h6>
              <ol start="7">
                <li><p className="mb-1">The Exide-ing app is the only way to participate in the Spinna Winna competition.</p></li>
                <li><p className="mb-1">Your spins will be saved in your Exide-ing wallet. You will only be credited with a spin once your sales invoice has been uploaded and validated.</p></li>
                <li>
                  <p className="mb-1">The following information is required when uploading a sales invoice</p>
                   <ul type="circle">
                      <li>Invoice number</li>
                      <li> Date of sale</li>
                      <li>Battery code</li>
                      <li>Quantity of Exide batteries sold as per the invoice</li>
                      <li>Upload of your customer invoice (Jpeg, PNG, PDF or CSV from Excel)</li>
                    </ul>
                </li>
                <li><p className="mb-1">Invoice uploads must be clear and legible. Photographs of computer screens will not be accepted.</p></li>
                <li><p className="mb-1">All invoices will be verified by an off-site team to ensure they reflect the completed Exide battery sale within the campaign period.</p></li>
                <li><p>The cut off for all sales invoices to be uploaded to the Exide-ing app is 04/02/2022 17:00 strictly. Any invoices uploaded thereafter will be rejected.</p></li>
              </ol>

              <h6 className="font-weight-700">Spinna Winna campaign dates</h6>
              <ol start="13">
                <li><p className="mb-1">The campaign period runs from 01/11/2021 till 31/01/2022, and only invoices from this time period are valid.</p></li>
                <li><p className="mb-1">Spins will accumulate with each Exide battery sold.</p></li>
                <li><p className="mb-1">Spins must be placed by Friday 12PM every week, in order for your prize to be processed the following Monday.</p></li>
                <li><p className="mb-1">All prizes will be sent to winners via SMS on the mobile number that you have registered on the Exide-ing App with by the following Friday.</p></li>
                <li><p className="mb-1">Prizes cannot be exchanged for cash value of prize.</p></li>
                <li><p>The cut off for all spin redemptions is strictly 11/02/2022 12:00, or you will forfeit your accumulated spins and be unable to participate in the Spinna Winna campaign.</p></li>
              </ol>

              <h6 className="font-weight-700">Spinning wheel voucher prizes</h6>
              <ol start="19">
                <li><p className="mb-1">There are four (4) prize voucher types: Airtime, KFC, BP fuel and Pick ‘n Pay.</p></li>
                <li><p className="mb-1">Airtime vouchers to be available for the following mobile networks, MTN, Vodacom, Cell C and Telkom.</p></li>
                <li><p className="mb-1">Limited number of vouchers are available, and all vouchers are until stocks last.</p></li>
                <li>
                  <p className="mb-1">Voucher values and quantities as below,</p>
                    <ul type="circle">
                      <li>3 000 x R20 Airtime vouchers</li>
                      <li>1 800 x R50 KFC vouchers</li>
                      <li>250 x R100 BP fuel vouchers</li>
                      <li>250 x R100 Pick ‘n Pay vouchers</li>
                    </ul>
                </li>
                <li><p className="mb-1">All vouchers will be issued via SMS to the mobile number you are registered on the Exide-ing App with.</p></li>
                <li><p className="mb-1">Exide will not be liable to refund vouchers if you register using the incorrect mobile number.</p></li>
                <li><p className="mb-1">BP fuel vouchers are accepted throughout South African branches of BP that have a Pick ‘n Pay Mini or Express in store.</p></li>
                <li><p className="mb-1">BP vouchers must be redeemed at Pick ‘n Pay tills in the BP store.</p></li>
                <li><p className="mb-1">Find a list of BP and Pick ‘n Pay branches by tapping <a href="https://www.bp.com/en_za/south-africa/home/products-and-services/nearest-bp.html" target="_blank">here</a></p></li>
                <li><p className="mb-1">Pick ‘n Pay vouchers are accepted throughout South African branches of Pick n Pay.</p></li>
                <li><p>Vouchers can take up to 72 hours to be issued.</p></li>
              </ol>

              <h6 className="font-weight-700">Spinning wheel E-Card: Weekly Lucky draw</h6>
              <ol start="30">
              <li><p className="mb-1">If you receive an E-card when spinning, you are entered into the weekly lucky draw.</p></li>
              <li><p className="mb-1">The lucky draw will take place every Friday at 12PM.</p></li>
              <li><p className="mb-1">Winners to be contacted via SMS, and a phone call to confirm the prize.</p></li>
              <li>
                <p className="mb-1">Prizes from the weekly draw will include,</p>
                <ul type="circle">
                  <li>20 x Spur vouchers valued at R1 500.</li>
                </ul>
              </li>
              <li><p className="mb-1">Redemption of the Spur meal voucher is subject to agreeing to the terms and conditions set in place by Exide and Spur.</p></li>
              <li><p className="mb-1">Prizes are not transferable for cash exchange.</p></li>
              <li><p className="mb-1">Prizes cannot be transferred to another person.</p></li>
              <li><p className="mb-1">Cost of transport to Spur will NOT be included.</p></li>
              <li><p className="mb-1">Spur Vouchers are to the value of R1 500, any amount over and above will be covered by the winner.</p></li>
              <li><p className="mb-1">Voucher excludes gratuity.</p></li>
              <li><p className="mb-1">Exide will not be held responsible for any injury, illness or loss at Spur branches.</p></li>
              <li><p>Valid at Spur branches countrywide. Find your nearest branch <a href="https://www.spursteakranches.com/za/find-a-spur" target="_blank">here</a>.</p></li>
              </ol>

              <h6 className="font-weight-700">The Grand Prize will be split into a choice of TWO (2) prizes,</h6>
              <ol start="42">
                <li><p className="mb-1">A Cape Town getaway for TWO people (2, sharing) including accommodation at the Table Bay Hotel with breakfast, WIFI, return flights and transfer costs, OR</p></li>
                <li><p>A half-day AMG Skidpan Experience in Pretoria or Cape Town, including return flights for 2 & airport transfers to the venue and back to the airport on the same day. (*Please note: this is a same day experience with return flights after the event)</p></li>
              </ol>

              <h6 className="font-weight-700">Grand Prize 1 - Cape Town Getaway</h6>
              <ol start="44">
                <li>
                  <p className="mb-1">There will be one (1) all-inclusive Grand Prize Getaways to the Table Bay Hotel in Cape Town. The getaway prize includes,</p>
                   <ul type="a">
                      <li>
                       <p className="mb-1">Airline tickets for two (2) to Cape Town International Airport.</p>
                       <ol type="i">
                        <li>Flights to depart from O.R Tambo International Airport (JHB), King Shaka International Airport (KZN), Bloemfontein International Airport, Port Elizabeth Airport, George Airport and Polokwane International Airport.</li>
                       </ol>
                      </li>
                      <li>
                        <p className="mb-1">Hotel transfers to and from the airport for two (2) people.</p>
                        <ol type="i">
                          <li>The transfer Carrier will be an UberX, and vouchers will be provided upon prize handover.</li>
                          <li>If the winner chooses to upgrade to Uber Black, this will be at the winner’s expense and NOT covered by Exide. Vouchers from Cape Town International Airport to Table Bay Hotel for two (2) included in prize (Arrival).</li>
                          <li>UberX vouchers from Table Bay Hotel to Cape Town International Airport for two (2) included in prize (Return).</li>
                        </ol>
                      </li>
                      <li>
                        <p className="mb-1">Accommodation will be at the Table Bay Hotel in Cape Town for two (2) and will include breakfast and WIFI.</p>
                        <ol type="i">
                          <li>Breakfast is available in The Atlantic restaurant daily from 7AM to 11AM strictly.</li>
                          <li>Room service costs will NOT be included in prize.</li>
                          <li>Accommodation is strictly for two (2) nights, and cannot be extended. If the winner chooses to extend, this will be at the winners expense and NOT covered by Exide.</li>
                          <li>Check-in is from 2PM and checkout on the day of departure is strictly at 11AM.</li>
                          <li>Table Bay Hotel accommodation to include a Luxury sea-facing King room.</li>
                        </ol>
                      </li>
                      <li>
                        <p className="mb-1">The Cost of activities such as the Table Bay day spa, tours and water sports will not be covered by Exide.</p>
                      </li>
                    </ul>
                </li>
                <li><p className="mb-1">The cost of activities outside of the hotel will not be covered by Exide.</p></li>
                <li><p>Exide will not be held responsible for any loss, injury or damage occurring during the Grand Prize getaway.</p></li>
              </ol>

              <h6 className="font-weight-700">Grand Prize 2 - AMG Driving Experience</h6>
              <ol start="47">
                <li><p className="mb-1">You must have a valid driver’s license and email address to redeem the AMG Driving Experience prize.</p></li>
                <li>
                  <p className="mb-1">Winners of the AMG Driving Experience may select one of two locations to redeem their prize:</p>
                  <ol type="a">
                    <li>Zwartkops Raceway in Pretoria, OR</li>
                    <li>Killarney Training Centre in Cape Town.</li>
                  </ol>
                </li>
                <li><p className="mb-1">Transfer costs will be covered to and from the event.</p></li>
                <li><p className="mb-1">The transfer Carrier will be an UberX, and vouchers will be provided upon prize handover.</p></li>
                <li><p className="mb-1">If the winner chooses to upgrade to Uber Black, this will be at the winner’s expense and NOT covered by Exide.</p></li>
                <li><p className="mb-1">Winners will be provided with a track breakdown prior to the experience.</p></li>
                <li><p className="mb-1">This is a half-day course, and will need to be booked in advance according to availability.</p></li>
                <li><p className="mb-1">If the winner opts to spend the night before or after the event, this will be at the winners expense.</p></li>
                <li><p className="mb-1">A meal and refreshments (non-alcoholic) will be served at the AMG Driving Experience. </p></li>
                <li>
                  <p className="mb-1">Winners will need to sign an indemnity form upon arrival. This form will include an agreement to the following, </p>
                  <ul type="circle">
                    <li><p className="mb-1">The winners’ driver’s license must be South African, and valid–not expired.</p></li>
                    <li><p className="mb-1">Temporary licenses will not be accepted.</p></li>
                    <li><p className="mb-1">Should an accident occur, the winner will be liable to pay a portion of the excess amount if said accident was caused by the winner.</p></li>
                    <li><p className="mb-1"> Mercedes-Benz SA are the sole owners of any content produced on the day, and can use this content freely.</p></li>
                  </ul>
                  </li>
                <li><p className="mb-1">Exide will not be held responsible for any damage or injuries occurred during the AMG Driving Experience.</p></li>
              </ol>

            </Col>
        </Row>
      </div>
    </>
)}
}

export default connect(
)(TermsAndConditions)