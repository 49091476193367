/**
 * Network Request Tool
 * https://github.com/umijs/umi-request
 */

import { extend } from 'umi-request'
import { notification, modal } from 'antd'
import { getToken } from './tokens'
import storage from 'shared/lib/storage'

/**
 * Exception handler
 */
const errorHandler = function(error) {

  const codeMessage = {
    200: 'The server successfully returned the requested data.',
    201: 'New or modified data is successful.',
    202: 'A request has entered the background queue (asynchronous task).',
    204: 'The data was deleted successfully.',
    400: 'The request was made with an error and the server did not perform any operations to create or modify data.',
    401: 'User does not have permission (token, username or password is incorrect).',
    403: 'The user is authorized, but access is forbidden.',
    404: 'The request is made for a record that does not exist and the server does not operate.',
    406: 'The format of the request is not available.',
    410: 'The requested resource is permanently deleted and will not be retrieved.',
    422: 'A validation error occurred when creating an object.',
    500: 'An error occurred on the server. Please check the server.',
    502: 'Gateway error.',
    503: 'The service is unavailable and the server is temporarily overloaded or maintained.',
    504: 'The gateway timed out.'
  }
 
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
   
    console.log("status", error.response.status);
    console.log("headers", error.response.headers);
    console.log("data", error.data);
    console.log("request", error.request);
    console.log(codeMessage[error.response.status]);

    let errormsgs = [];

    for (let c in error.data.data) {
        errormsgs.push(error.data.data[c]);
    }

    //console.log(errmsgs['0']);
    if (error.data.data) {
       notification.error({
        message: error.data.message,
        description: errormsgs['0']
      })
    } else {
       notification.error({
        message: error.data.error,
        description: codeMessage[error.response.status]
      })
    }

  } else {
    // The request was made but no response was received or error occurs when setting up the request.
    notification.error({
      description: 'Cannot connect to the server. This could be due to your internet connection (signal or data).',
      message: 'Network error'
    })

    console.log(error.message);
  }

  throw error; // If throw. The error will continue to be thrown.
};


//const basePath = 'http://localhost:8000';
const basePath = 'https://batterybucks-admin.gandgdev.co.za';
//const basePath = 'https://app.exide-ing.co.za';

const apiPrefix = process.env.REACT_APP_PREFIX || 'api/v1'

const request = extend({
  prefix: `${basePath}/${apiPrefix}`,
  credentials: 'omit',
  headers: {
    'Content-Type': 'application/json' // 'multipart/form-data'
  },
  errorHandler
})


request.interceptors.request.use(async (url, options) => {
  if (
    options.method === 'post' ||
    options.method === 'put' ||
    options.method === 'delete' ||
    options.method === 'get'
  ) {
    const token = storage.local.get('tokenString')

    const headers = {
      Authorization: `Bearer ${token}`
    };
    return {
      url,
      options: { ...options, headers },
    };
  }
});

export default request;