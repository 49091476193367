import api from 'shared/api/request'
import { selectToken } from 'shared/state/actions'
import { push } from 'connected-react-router'
import { notification, modal } from "antd";

export const register = data => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  api
    .post('/register', { data })
    .then(({data, message, error }) => {
      dispatch({type: 'shared/form/SUBMITTED'})
      if (data) {
        modal.info({
          title: 'Awesome!',
          content: 'You’ll receive SMS confirmation of your password in 24 hours. Happy earning.',
          okText: 'Login',
          okButtonProps: { className: "ant-btn-round ant-btn-lg"},
          onOk() {
            console.log('OK');
            dispatch(push("/login"));
          },
        });
        return dispatch(push("/"));
      } 
    })
    .catch(body => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: body
      })
    })
}

export const registerStoreManager = data => dispatch => {
  dispatch({ type: 'shared/form/SUBMITTING' })
  api
    .post('/store-member', { data })
    .then(({data, message }) => {
      dispatch({type: 'shared/form/SUBMITTED'})
      if (data) {
        modal.info({
          title: 'Awesome!',
          content: 'You’ll receive SMS confirmation of your password in 24 hours. Happy earning.',
          okText: 'Login',
          okButtonProps: { className: "ant-btn-round ant-btn-lg"},
          onOk() {
            console.log('OK');
            dispatch(push("/login"));
          },
        });
        return dispatch(push("/"));
      }
    })
    .catch(body => {
      dispatch({
        type: 'shared/form/SUBMITTED',
        messages: body
      })
    })
}

export const fetchBranches = () => dispatch => {
  //dispatch({ type: 'shared/list/FETCHING' })
  api
    .get('/branches')
    .then(({ data }) => {
      dispatch({
        type: 'shared/branches/FETCHED_BRANCHES',
        branches: data
      })
    })
    .catch(error => {
      dispatch({ type: 'shared/list/FETCH_ERROR', messages: error })
    })
}

export const fetchReps = () => dispatch => {
  //dispatch({ type: 'shared/foo/FETCHING' })
  api
    .get('/reps')
    .then(({ data }) => {
       dispatch({ 
        type: "shared/reps/FETCHED_REPS", 
        reps: data
      });
    })
    .catch(error => {
      dispatch({ type: 'shared/list/FETCH_ERROR', messages: error })
    })
}

export const stores = () => dispatch => {
  dispatch({ type: 'shared/list/FETCHING' })
  api
    .get('/stores')
    .then(({ data }) => {
      console.log(data)
      dispatch({
        type: 'shared/item/FETCHED_ITEM',
        item: data
      })
    })
    .catch(error => {
      dispatch({ type: 'shared/list/FETCH_ERROR', messages: error })
    })
}

