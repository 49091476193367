import React from 'react'
import ReactDOM from 'react-dom'
import api from "shared/api/request";
//import requestForm from "shared/api/requestForm"
import { modal, notification } from "antd";
import { push } from "connected-react-router";

export const getUserProfile = () => (dispatch) => {
  dispatch({ type: "shared/list/FETCHING" });
  api
    .get(`/user`)
    .then((user) => {
      dispatch({ 
        type: "shared/item/FETCHED_ITEM",
        item: user.data
      });
      dispatch({
        type: "UPDATE_WALLET_BALANCE",
        payload: user?.data?.wallet?.balance,
      });
    })
    .catch(({ error }) => {
      dispatch({ type: "shared/list/FETCH_ERROR" });
    });
};

export const createinvoice = (rData, file) => (dispatch) => {

  // const quantity = rData
  //   .map((item) => item.quantity)
  //   .reduce((a, b) => +a + +b, 0);

  // const itemsD = rData.items.reduce((obj, i) => {
  //   return {
  //     ...obj,
  //     [i.items]: +i.quantity,
  //   };
  // }, {});

  const _data = {
    date_of_sale: rData.date_of_sale,
    quantity: rData.quantity,
    items: rData.items.join(", "),
    receipt_number: rData.receipt_number,
    file,
  };

  dispatch({ type: "shared/form/SUBMITTING" });
  api
    .post(`/receipts`, { data: _data })
    .then(({ data, message }) => {
      if (data) {
        modal.info({
          title: 'BOOM!',
          content: (
            <div>
              <p>You’ve uploaded your invoices and your Exide-ing wallet is about to be recharged with spins!</p>
              <p>Please note: The Exide team will validate your invoice in 24 hours.</p>
            </div>
          ),
          okText: 'Exide-ing wallet',
          okButtonProps: { className: "ant-btn-round ant-btn-lg"},
          onOk() {
            dispatch(push("/wallet"));
          },
        });

        //dispatch(push("/invoice-confirm"));
      }
    })
    .catch(({ error }) => {
      dispatch({ type: "shared/form/SUBMITTED", messages: error});
      
      notification.error({
        message: "Error uploading receipt",
        description: error,
      });
    });
};

export const fetchWallets = () => (dispatch) => {
  dispatch({ type: "shared/list/FETCHING" });
  api
    .get(`/wallets`)
    .then(({ data }) => {
      dispatch({
        type: "shared/item/FETCHED_ITEM",
        item: data,
      });
    })
    .catch(({ error }) => {
      console.info("Search Batches error", error);
      dispatch({ type: "shared/list/FETCH_ERROR", messages: error });
    });
};

export const fetchBatteryCodes = () => (dispatch) => {
  dispatch({ type: "shared/list/FETCHING" });
  api
    .get(`/codes`)
    .then(({ data }) => {
      dispatch({
        type: "shared/item/FETCHED_ITEM",
        item: data,
      });
    })
    .catch(({ error }) => {
      console.info("Search Batches error", error);
      dispatch({ type: "shared/list/FETCH_ERROR", messages: error });
    });
};

export const purchaseAir = (value) => (dispatch) => {
  dispatch({ type: "shared/form/SUBMITTING" });
  api
    .post(`/purchase-airtime?Units=R${value}`)
    .then(({ data, message }) => {
      if (data) {
        dispatch({ type: "shared/form/SUBMITTED", messages: message });
        notification.success({
          message: `Voucher Purchase`,
          description: message,
        });
      }
    })
    .catch(({ error }) => {
      dispatch({ type: "shared/form/SUBMITTED", messages: error });
    });
};

export const purchaseVoucher = (value) => (dispatch) => {
  dispatch({ type: "shared/form/SUBMITTING" });
  const pred =
    value[0] === "PINNED_AIRTIME" || "PINLESS_DATA"
      ? `${value[2]}`
      : `R${value[2]}`;
  api
    .get(`/purchase-voucher?Units=${pred}&Product=${value[0]}`)
    .then(({ data, message }) => {
      if (data) {
        notification.success({
          description: message,
          message,
        });

        setTimeout(() => {
          // eslint-disable-next-line no-undef
          window.location.reload();
        }, 3000);
      }
    })
    .catch(({ error }) => {
      dispatch({ type: "shared/form/SUBMITTED", messages: error });
    });
};

export const fetchMerch = () => (dispatch) => {
  dispatch({ type: "shared/list/FETCHING" });
  api
    .get(`/merch`)
    .then(({ data }) => {
      dispatch({
        type: "shared/item/FETCHED_ITEM",
        item: data,
      });
    })
    .catch(({ error }) => {
      console.info("Search Batches error", error);
      dispatch({ type: "shared/list/FETCH_ERROR", messages: error });
    });
};

export const fetchSingleMerch = (id) => (dispatch) => {
  api
    .get(`/merch/${id}`)
    .then(({ data }) => {
      dispatch({
        type: "shared/item/FETCHED_ITEM",
        item: data,
      });
    })
    .catch(({ error }) => {
      console.error("Search Batches error", error);
    });
};

export const getProducts = () => (dispatch) => {
  dispatch({ 
    type: "shared/list/FETCHING"
  });
  api
    .get("/merch")
    .then(({ data }) => {
      dispatch({ type: "shared/list/FETCHED_ITEMS", items: data });
    })
    .catch(({ error }) => {
      dispatch({ type: "shared/list/FETCH_ERROR", messages: error });
    });
};

export const addItemToStore = (payload) => (dispatch) => {
  dispatch({ type: "ADD_CART_ITEM", payload });
};

export const removeItemFromStore = (id) => (dispatch) => {
  dispatch({ type: "REMOVE_CART_ITEM", payload: id });
};

export const removeItemFromStoreButton = (id) => (dispatch) => {
  dispatch({ type: "REMOVE_CART_ITEM_BUTTON", payload: id });
};

export const updateCurrentBalance = (amount) => (dispatch) => {
  dispatch({ type: "UPDATE_WALLET_BALANCE", payload: amount });
};

export const getSelectedMerch = (merchID) => (dispatch) => {
  dispatch({ type: "SELECTED_MERCH", payload: merchID });
};

export const checkout = (data, userid, useremail) => (dispatch) => {
  dispatch({ type: "shared/form/SUBMITTING" });

  const _data = data?.map((item) => {
    return {
      product_id: item?.id,
      quantity: item?.quantity,
      userid: userid,
      email: item?.email,
      ref_id: item?.ref_id
    };
  });

  api
    .post(`/checkout`, { data: _data })
    .then(({ data, message }) => {
      if (data) {
        dispatch({ type: "shared/form/SUBMITTED", messages: message });
      }
    })
    .catch(({ error }) => {
      dispatch({ type: "shared/form/SUBMITTED", messages: error });
    });
};

export const update = (values, ref_id) => (dispatch) => {
  dispatch({ type: "shared/form/SUBMITTING" });

  const _data = {
    network: values.network,
    ref_id: ref_id
  };

  api
    .post(`/update`, { data: _data })
    .then(({ data, message }) => {
      if (data) {
        dispatch({ type: "shared/form/SUBMITTED", messages: message });
        console.log(message)
      }
    })
    .catch(({ error }) => {
      dispatch({ type: "shared/form/SUBMITTED", messages: error });
    });
};


