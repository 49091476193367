/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from "react";

import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { Button, Icon, Card, Col, Row, Progress, Alert , PageHeader} from "antd";
//import * as action from "./state/index";
import { getUserProfile } from './state/index'

import wheel from "shared/assets/wheel.png";
import step1 from "shared/assets/step1.svg";
import step2 from "shared/assets/step2.svg";
import step3 from "shared/assets/step3.svg";
import step4 from "shared/assets/step4.svg";
import step5 from "shared/assets/step5.svg";
import step6 from "shared/assets/step6.svg";
import buymerch from "shared/assets/buy-merch.png";
import ReturnButton from "./return";

class Wallet extends React.Component {
  
  componentDidMount() {
    //const { getUserProfile } = this.props;
    //getUserProfile();

    this.props.getUser();
  }

  render() {
    const { wallet } = this.props;
    const { username } = this.props;
   
    return (
      <>
        <div className="container-fluid page-header">
         <ReturnButton />
          <Row>
            <Col>
              <h1>
                <span className="font-weight-300">{ username }'s </span>Wallet
              </h1>
            </Col>
          </Row>
        </div>

        <div className="container-fluid p-3">
          <Row>
            <Col>
              <Card style={{ marginBottom: 40 }} bordered={false} className="ant-card-grey">
                <h2 className="text-center m-0">
                  <span className="font-weight-300">You have</span><br/>
                  <span className="display-1 font-weight-700">
                    { wallet?.balance } Spins Available
                   </span>
                </h2>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2 className="text-center">
                <span className="font-weight-300">What is</span><br/>Spinna Winna?
              </h2>
              <p>Think of it as your fave gameshow, only this time, you’re gonna be a winna.</p>
              <p>For every Exide battery sold, you earn a spin of the Exide-ing Spinna wheel and an entry to the grand prize draw. The wheel gives you equal chances of winning the following prizes,</p>
              
              <Link to={'./merch'}>
                <div className="bg-primary rounded py-3 px-2 my-5 d-flex justify-content-center box-shadow">
                  <div className="position-relative align-coin">
                    <img className="img-fluid" src={wheel} alt="" />
                    <h2 className="text-white my-2">
                      <span className="font-weight-300">Ready, set, </span>spin!
                    </h2>
                  </div>
                </div>
              </Link>

              <ul className="list-icon">
                <li className="prize">An Exide-ing E-prize</li>
                <li className="airtime">R20 airtime vouchers</li>
                <li className="kfc">R50 KFC vouchers</li>
                <li className="bp">R100 BP vouchers</li>
                <li className="pnp">R100 Pick ‘n Pay vouchers</li>
              </ul>

           </Col>
          </Row>
        </div>

        <div className="container-fluid bg-primary py-4 px-3">
          <Row>
            <h2 className="text-center mt-2 text-white">
              <span>DRAW PRIZES</span>
            </h2>

            <ul className="list-icon text-white">
              <li className="spur">R1 500 Spur vouchers</li>
              <li className="amg">You can WIN 1 of 3 AMG Driving Experiences or,</li>
              <li className="hotel">1 of 2 all-expenses paid trips to Table Bay Hotel in Cape Town</li>
            </ul>
          </Row>
        </div>

        <div className="container-fluid bg-grey-100 py-4 px-3">
          <Row>
            <h2 className="text-center mt-2">
              <span className="font-weight-300">How to redeem</span><br/>awesome vouchers
            </h2>
          </Row>

          <Row>
            <Col span={12}>
              <div className="text-center">
                <div className="step-section my-4">
                  <h6 className="font-weight-700 text-uppercase">Step 1</h6>
                  <img className="pb-20" src={step1} alt="Exide" />
                  <p className="mt-2">Sell as many Exide batteries as you can. And then sell some more!</p>
                </div>
              </div>
            </Col>

            <Col span={12}>
              <div className="text-center">
                <div className="step-section my-4">
                  <h6 className="font-weight-700 text-uppercase">Step 2</h6>
                  <img className="pb-20" src={step2} alt="Exide" />
                {/* <div className=" text-center" style={{
                    backgroundColor: '#ef2924',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    borderRadius: '50px',
                    width: '81px',
                    height: '81px',
                    lineHeight: 4,
                    fontSize: '20px'
                  }}>
                    <p className="text-white"><strong>20BB</strong></p>
                  </div>*/}
                  <p className="mt-2">Upload your invoices to the Exide-ing app</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div className="text-center">
                <div className="step-section my-4">
                  <h6 className="font-weight-700 text-uppercase">Step 3</h6>
                  <img src={step3} alt="Exide" />
                  <p className="mt-2">Earn a spin for every Exide battery sold</p>
                </div>
              </div>
            </Col>

            <Col span={12}>
              <div className="text-center">
                <div className="step-section my-4">
                  <h6 className="font-weight-700 text-uppercase">Step 4</h6>
                  <img src={step4} alt="Exide" />
                  <p className="mt-2">Give the Spinna wheel a go</p>
                </div>
              </div>
            </Col>
           </Row>
           <Row>
            <Col span={12}>
              <div className="text-center">
                <div className="step-section my-4">
                  <h6 className="font-weight-700 text-uppercase">Step 5</h6>
                  <img src={step5} alt="Exide" />
                  <p className="mt-2">Redeem your prize using your registered mobile number</p>
                </div>
              </div>
            </Col>

            <Col span={12}>
              <div className="text-center">
                <div className="step-section my-4">
                  <h6 className="font-weight-700 text-uppercase">Step 6</h6>
                  <img src={step6} alt="Exide" />
                  <p>Win!</p>
                </div>
              </div>
            </Col>
           </Row>
        </div>
      </>
    );
  }
}

// const mapStateToProps = ({
//   shared: {
//     item: { item },
//   },
// }) => ({
//   wallet: item?.wallet,
// });
//const mapDispatchToProps = action;

const mapStateToProps = state => {
  const wallet = state.shared.item.item?.wallet;
  const username = state.shared.item.item?.name;
 
  return {
    wallet, 
    username
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getUser: () => {
          dispatch(getUserProfile())
      }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
