/* eslint-disable import/no-mutable-exports */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Icon, Input, Button, Checkbox } from 'antd';
import MainFooter from 'shared/components/layouts/MainFooter';
import { Link } from 'react-router-dom';
import logo from 'shared/assets/logo-light.svg';
//import batterybucks from 'shared/assets/batterybucks-logo.svg'
import keyvisual from "shared/assets/key-visual.png";


function LoginBodyClass() {
  useEffect(() => {
    document.body.className = "page--register";
    
    return () => {
      document.body.className = "";
    }
  }, []);
}


const Register = props => {

  LoginBodyClass()

  return (
    <div className="auth-wrapper">
      <div className="auth-content d-flex flex-column">
        <Row className="d-flex flex-grow-1 d-flex flex-column">
          <Col span={24} className="logo-lg text-center mb-3">
            <a href="./">
              <img src={logo} alt="Exide" />
            </a>
          </Col>
          <Col span={24} className="mb-3">
            <h1 className="text-center text-white my-xl">
              <span className="font-weight-300">Please select</span><br/>registration type
            </h1>
          </Col>
          <Col span={24} className="">
            <Row>
              <div className="text-center mb-3">
                <Link className="ant-btn ant-btn-secondary ant-btn-round ant-btn-lg" to='/register-individual'>Individual</Link>
              </div>
              <div className="text-center mb-4">
                <Link className="ant-btn ant-btn-secondary ant-btn-round ant-btn-lg" to='/register-store-manager'>Store Manager</Link>
              </div>
            </Row>
          </Col>
          <Col span={24}>
            <h1 className="text-center text-white my-md-2">
             <span className="display-1 font-weight-700">Spinna</span><br/>
             <span className="display-2 font-weight-700">and be a big</span><br/>
             <span className="display-1 font-weight-700">Winna</span>
            </h1>
          </Col>
        </Row>
      </div>
      <Row></Row>
    </div>
  )
}


export default Register;