import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Result, Button } from 'antd'

class invoiceConfirm extends Component {

render() {

return (
  <Result
    status="warning"
    title="Congrats! You’ve successfully added your invoice, your invoice will be approved in 48hrs and funds allocated to your wallet."
    extra={[
        <Button href="./invoice" type="default" key="console">
          Add another invoice
        </Button>,
        <Button href="./dashboard" type="default" key="console">
          Home
        </Button>
    ]}

  />
)

}

}

export default connect(

)(invoiceConfirm)