export const getModules = id =>
  !id
    ? []
    : [
        {
          title: 'Home',
          key: 'home',
          icon: 'home',
          link: '/dashboard',
          className: 'home'
        },
        {
          title: 'Upload invoices',
          key: 'addInvoice',
          icon: 'plus',
          link: '/invoice',
          className: 'invoice'
        },
        {
          title: 'Exide-ing wallet',
          key: 'wallet',
          icon: 'wallet',
          link: '/wallet',
          className: 'wallet'
        },
        {
          title: 'Exide-ing education',
          key: 'education',
          icon: 'book',
          link: '/education',
          className: 'education'
        },
        // {
        //   title: 'Merch',
        //   key: 'merch',
        //   icon: 'shoppingcartOutlined',
        //   link: '/merch'
        // },
        // {
        //   title: 'Merch History',
        //   key: 'history',
        //   icon: 'list',
        //   link: '/profile'
        // },
        {
          title: 'Spinna Winna',
          key: 'spin',
          icon: 'spin',
          link: '/merch',
          className: 'spin'
        },
        // {
        //   title: 'Cart',
        //   key: 'cart',
        //   icon: 'mail',
        //   link: '/cart',
        //   className: 'cart'
        // },
        {
          title: 'Contact Us',
          key: 'contactUs',
          icon: 'mail',
          link: '/contact',
          className: 'contact'
        },
        // {
        //   title: 'Terms & Conditions',
        //   key: 'tandcs',
        //   icon: '',
        //   link: '/terms-and-conditions'
        // }
      ]
